import React, { useEffect, useState } from "react";

import {
  Routes,
  BrowserRouter as Router,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

const resolvePath = (path) => {
  switch (path[2]) {
    case "descansa":
      return `https://thetestnuevo.myshopify.com/products/bolsita-de-cbd-gummies?dealerurl=${path[1]}`;
    case "energia":
      return `https://thetestnuevo.myshopify.com/products/aceite-energize-5ml-500mg-extracto-de-hemp-cbd-divergentes-travel-size?dealerurl=${path[1]}`;
    case "promo":
      return `https://thetestnuevo.myshopify.com/products/sensory-gratis?dealerurl=${path[1]}`;
    default:
      break;
  }
};

export const AppRouter = () => {
  const location = useLocation();
  let path = location.pathname.split("/");
  if (path[2] !== undefined && path[2] !== String()) {
    window.location.href = resolvePath(path);
  } else if (path[1] !== undefined && path[1] !== String()) {
    window.location.href = `https://sweeven.life?dealerurl=${path[1]}`;
  } else {
    window.location.href = `https://sweeven.life`;
  }
  // window.location.href = `https://sweeven.life?dealerurl=${location.pathname.replace(
  //   "/",
  //   String()
  // )}`;
  return null;
  //return <Navigate to={"https://sweeven.life"} replace></Navigate>;
};
